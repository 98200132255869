import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

type IEscuelaInfoGral = Entidad.IEscuelaInfoGral;

export const _DiccEscuelaInfoGeneral: Map<number, IEscuelaInfoGral> = new Map();

// const REQUESTID = Entidad.CTipoRequest.EscuelaInformacionGeneral;

/* export async function _GetListEscuelaInfoGeneral(kinders: Array<IEscuela>): Promise<DataDRequest.IRequestResponseListA<Entidad.IEscuelaInfoGral>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/ObtenerInformacionGeneralLista";
    return new ArrayV2()
        ._Push(...kinders)
        ._MapAwait(async ({ IdKinder }) => {
            const currentDateStr = await DataUtil._GetMaxDate(REQUESTID, IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "Modificacion": currentDateStr,
                "IdEscuela": IdKinder
            }

            return DataDRequest._Request4List(url, "GET", params, REQUESTID, IdKinder)
        })
} */

export async function _SvGetListEscuelaInfoGeneral(idKinder: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/ObtenerInformacionGeneralLista";
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdEscuela": idKinder,
        "Modificacion": null
    }

    return DataDRequest._Request5<Entidad.IEscuelaInfoGral[]>(url, "GET", params)
}

export async function _SvAgregarNuevaInformacionGeneral(infoGral: IEscuelaInfoGral, archivo: File) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/AgregarNuevaInformacionGeneral";
    const formData = new FormData();
    formData.append("IdUsuario", DataUtil._Usuario.IdUsuario + "");
    formData.append("IdEscuela", infoGral.IdEscuela + "");
    formData.append("Titulo", infoGral.Titulo);
    formData.append("Descripcion", infoGral.Descripcion);
    formData.append("Inicio", infoGral.Inicio);
    formData.append("Niveles", infoGral.IdsNiveles.toString());
    formData.append("Grados", infoGral.IdsGrados.toString());
    formData.append("Grupos", infoGral.IdsGrupos.toString());

    formData.append("Archivo", archivo, archivo.name);

    return DataDRequest._Request3(url, "POST", formData);
}

export async function _SvEditarInformacionGeneral(infoGeneral: IEscuelaInfoGral, archivo: File) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/EditarInformacionGeneral";
    const formData = new FormData();

    formData.append("IdUsuario", DataUtil._Usuario.IdUsuario + "");
    formData.append("Titulo", infoGeneral.Titulo);
    formData.append("IdEscuela", infoGeneral.IdEscuela + "");
    formData.append("Descripcion", infoGeneral.Descripcion);
    formData.append("Inicio", infoGeneral.Inicio);
    formData.append("Niveles", infoGeneral.IdsNiveles.toString());
    formData.append("Grados", infoGeneral.IdsGrados.toString());
    formData.append("Grupos", infoGeneral.IdsGrupos.toString());
    formData.append("IdInfoGeneral", infoGeneral.Id + "");

    if (archivo != null) {
        formData.append("Archivo", archivo, archivo.name);
    }

    return DataDRequest._Request3(url, "POST", formData);
}

export async function _SvEliminarInformacionGeneral(idInfoGral: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/EliminarInformacionGeneral";
    const params = {
        "IdInfoGeneral": idInfoGral,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }
    return DataDRequest._Request5(url, "POST", params);
}

type PromiseInfoGralInfoArchivo = Promise<DataDRequest.IRequestResponseA<Entidad.IInfoArchivo[]>>
interface IInfoGralGetInfoArchivoRecord {
    Datos: Entidad.IInfoArchivo[];
    Promise: PromiseInfoGralInfoArchivo;
}

let infoGralGetInfoArchivoRecords = new Map<number, IInfoGralGetInfoArchivoRecord>();
export async function _SvGetInfoArchivos(idInfoGral: number, modificacion: string): Promise<DataDRequest.IRequestResponseA<Entidad.IInfoArchivo[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/ObtenerInfoArchivosInformacionGeneral";
    const params = {
        "IdInfoGeneral": idInfoGral
    }

    let info = infoGralGetInfoArchivoRecords.get(idInfoGral)
    if (!info || info["__Modificacion"] != modificacion) {
        info = {
            Datos: null,
            Promise: null
        }
        info["__Modificacion"] = modificacion;
        infoGralGetInfoArchivoRecords.set(idInfoGral, info);
    } else if (info?.Promise) {
        return info.Promise
    } else if (info?.Datos) {
        return {
            Resultado: 1,
            Datos: info.Datos
        }
    }

    const res = DataDRequest._Request5<Entidad.IInfoArchivo[]>(url, "GET", params)
        .then(res => {
            res.Datos = res.Datos || [];
            if (res.Resultado > 0) {
                info.Datos = res.Datos;
            }
            return res
        })
        .catch(() => ({
            Resultado: -100,
        }))
        .finally(() => {
            info.Promise = null;
        })

    info.Promise = res;
    return res;
}

export function _SvGetUrlObtenerArchivo(idArchivo: number, tamanio: 1 | 2 | 3, modificacion: string) {
    return `${Global._GLOBAL_CONF.URL_RSTV2}escuela/ObtenerArchivoInfoGeneral?IdArchivo=${idArchivo}&Tamanio=${tamanio}&Modificacion=${modificacion}`;
}